import React from "react"
import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <SEO title="Thirty Madison - Virtual-First Specialized Healthcare" description="Thirty Madison is a family of specialized healthcare brands creating exceptional outcomes for all." />
    <iframe
      title="Welcome to Thirty Madison"
      src="https://home.thirtymadison.com"
      style={{ height: "100vh", width: "100%", border: "none" }}
    />
  </>
)

export default IndexPage
